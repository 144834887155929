<template>
  <div class="outer-wrapper" v-if="show">
    <v-overlay absolute :opacity="0.5"></v-overlay>

    <div class="inner-wrapper">
      <v-alert
        dismissible
        border="left"
        icon="$information"
        max-width="500"
        v-model="show"
      >
        <p>
          Für die optimale Nutzung empfehlen wir, die huddlenow
          Desktop-Applikation zu verwenden.
        </p>
        <p class="mb-0">
          <a href="https://huddlenow.net/download">
            Desktop-Applikation jetzt herunterladen
          </a>
        </p>
      </v-alert>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownloadAd",
  data() {
    return {
      show: this.$store.state.user.showDownloadAd,
    };
  },
  watch: {
    show(value) {
      if (!value) {
        this.$store.commit("user/dismissDownloadAd");
      }
    },
  },
};
</script>

<style scoped>
.outer-wrapper {
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
}
.inner-wrapper {
  position: absolute;
  top: 32px;
  left: 50%;
  z-index: 5;
}
.v-alert {
  position: relative;
  left: -50%;
}
</style>
